/**
 */
.pageMenu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  z-index: 100;
  transform: translateY(calc(-100vh + 50px));
  transition: all .5s ease-in-out;
}
.pageMenu.opened {
  transform: translateY(0);
}
.pageMenu .content {
  height: calc(100vh - 50px);
  box-sizing: border-box;
  padding: 20px;
  overflow-y: auto;
  background: rgba(0,0,0,.9);
  color: white;
}
.pageMenu .toggleButtons {
  color: white;
  text-align: right;
}
.pageMenu .toggleButton {
  display: inline-block;
  width: 55px;
  height: 50px;
  box-sizing: border-box;
  border-bottom: 3px solid black;
  border-right: 3px solid black;
  text-align: center;
  cursor: pointer;
}
.pageMenu .toggleButton:first-child {
  border-left: 3px solid black;
}
.pageMenu .toggleButton.active {
  background: black;
}
.pageMenu .toggleButton span {
  font-size: 40px;
}
