/**
 */
.lobby {
  display: grid;
  grid-gap: 30px;
  grid-template-areas:
    "pageHeader      "
    "rooms           "
    "createRoomButton";
  margin: 30px 0;
}
.lobby > .pageHeader {
  grid-area: pageHeader;
}
.lobby > .rooms {
  grid-area: rooms;
}
.lobby > .createRoomButton {
  grid-area: createRoomButton;
}

/**
 */
@media (min-width: 769px) {
  .lobby .rooms {
    margin: 30px;
  }
}

/**
 */
.lobby .createRoomButton {
  margin: 0 auto;
  font-size: 16px;
  cursor: pointer;
}

/**
 */
.lobby .room {
  display: grid;
  grid-gap: 10px;
  grid-template-areas:
    "topLeftSeat    .      topRightSeat   "
    "teamWithLeft   VSorGO teamWithRight  "
    "bottomLeftSeat .      bottomRightSeat";
  grid-template-columns: 1fr 200px 1fr;
  grid-template-rows: auto 48px auto;
  text-align: center;
  padding: 10px 0;
  border: 1px solid black;
}
.lobby .room > .topLeftSeat {
  grid-area: topLeftSeat;
}
.lobby .room > .topRightSeat {
  grid-area: topRightSeat;
}
.lobby .room > .bottomLeftSeat {
  grid-area: bottomLeftSeat;
}
.lobby .room > .bottomRightSeat {
  grid-area: bottomRightSeat;
}
.lobby .room > .versus,
.lobby .room > .goButton {
  grid-area: VSorGO;
}
.lobby .room > .teamWith.left {
  grid-area: teamWithLeft;
}
.lobby .room > .teamWith.right {
  grid-area: teamWithRight;
}

/**
 */
.lobby .room + .room {
  margin-top: 30px;
}

/**
 */
.lobby .versus,
.lobby .goButton,
.lobby .teamWith {
  font-size: 40px;
}
.lobby .versus {
  font-style: italic;
}
.lobby .versus::before {
  content: '/';
  position: relative;
  top: 100%;
  left: 10px;
}
.lobby .versus::after {
  content: '/';
  position: relative;
  top: -100%;
  left: -10px;
}
.lobby .goButton {
  margin: 0 auto;
}
.lobby .goButton::before {
  content: '👆';
  position: relative;
  top: 62px;
  left: 57px;
  display: inline-block;
  width: 42px;
  height: 54px;
}
.lobby .goButton::after {
  content: '👇';
  position: relative;
  top: -62px;
  left: -57px;
  display: inline-block;
  width: 42px;
  height: 54px;
}
.lobby .goButton span {
  padding: 5px;
  border: 1px solid black;
  border-radius: 50%;
  background-color: rgba(230, 230, 230, .5);
  cursor: pointer;
  display: inline-block;
  width: 50px;
  height: 40px;
  vertical-align: baseline;
  font-size: 34px;
}
.lobby .goButton span:hover {
  padding: 10px;
  margin: -5px;
  background-color: rgba(230, 230, 230, 1);
}

/**
 */
.lobby .roomSeat {
  display: grid;
  grid-gap: 5px;
}
.lobby .topLeftSeat .roomSeat,
.lobby .topRightSeat .roomSeat {
  grid-template-areas:
    "seatButton"
    "playerName";
  grid-template-rows: 20px auto;
}
.lobby .bottomLeftSeat .roomSeat,
.lobby .bottomRightSeat .roomSeat {
  grid-template-areas:
    "playerName"
    "seatButton";
  grid-template-rows: auto 20px;
}
.lobby .roomSeat > .playerName {
  grid-area: playerName;
}
.lobby .roomSeat > .seatButton {
  grid-area: seatButton;
}

/**
 */
.lobby .playerName {
  font-weight: bold;
}
.lobby .mySeat .playerName {
  text-decoration: underline;
}
.lobby .seatButton > button {
  cursor: pointer;
}
.lobby .seatButton > button:disabled {
  cursor: not-allowed;
}
