@import-normalize;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.spade, .black {
  color: black;
}
.club {
  color: rgb(0, 140, 0);
}
.heart, .red {
  color: rgb(170, 0, 0);
}
.diamond {
  color: rgb(0, 80, 180);
}
